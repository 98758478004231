<template>
  <div v-swiper:BoxSlider="settings">
    <div class="swiper-wrapper mt-12">
      <slot />
    </div>
    <div class="relative text-center top-0 lg:hidden box-pagination fibre-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'BoxSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        autoplay: {
          speed: 1000,
          delay: 3000,
        },
        pagination: {
          clickable: true,
          el: '.box-pagination',
        },
        allowTouchMove: true,
      }
    }
  }
}
</script>
