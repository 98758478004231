<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 border-2 text-white mt-6 border-white bg-black font-lato p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-semibold">You're in!</p>
          <p class="text-sm">Keep an eye on your inbox for our words of wisdom and some outstanding work.</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white font-lato bg-black p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-semibold">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form  :class="{ hidden: success }" name="news-letter" class="grid gap-x-6 gap-y-6 md:grid-cols-4" @submit.stop>
      <validation-provider v-slot="{ errors }" name="Email" class="md:col-span-4">
        <div>
          <div v-animate="'fade-bottom'" class="w-10/12 my-6">
          <input
              id="form-email"
              v-model="form.email"
              :class="{ error: errors[0] }"
              type="text"
              class="border-black w-full text-black border-b"
              placeholder="Email address *"
          />
            <p class="text-12 mt-2 text-black">We will not sell or distribute your email address to third parties. You can unsubscribe at any time. View our
              <a href="/privacy-policy" class="-mb-3 learn-moreef">
                <span class="circle" aria-hidden="true"></span>
                <span class="button-text font-base relative py-2 flex gap-x-4 underline">privacy policy</span>
              </a>
            </p>
          </div>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
        </div>
      </validation-provider>
      <div class="md:col-span-4">
        <button type="button" v-animate="'fade-bottom'" @click="passes(SendMessage)" class="learn-more">
                  <span class="circle" aria-hidden="true">
                  </span>
          <span class="button-text flex gap-x-4">Send me the Knowledge <img width="16" height="22" src="app/themes/wordpress/assets/images/arrow.svg" alt="arrow"></span>
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'NewsLetter',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      form: {}
    }
  },
  methods: {
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/subscribe', this.form)
          .then((response) => {
            this.form = {} // Clear input fields.
            this.$nextTick(() => this.$refs.form.reset())
            this.success = response.data.message
            this.error = false
            /*
            this.$notify({
              type: 'primary',
              showMethod: 'fade in right',
              message: response.data.message
            })*/
          })
          .catch((err) => {
            this.success = false
            this.error = err.response.data.message
            /*
            this.$notify({
              message: err.response.data.message
            })
            */
          })
          .then(() => {
            this.loading = false
          })
    }
  }
}
</script>
<style>
input:active{
  border:none!important;
}
input:focus-visible{
  outline-width: 0px;
}
textarea:focus-visible{
  outline-width: 0px;
}
</style>
