import Vue from 'vue'
window.Vue = Vue
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import mixitup from 'mixitup'; // loaded from node_modules
import mixitupPagination from './mixitup-pagination';
mixitup.use(mixitupPagination);
import $ from "jquery";
import axios from 'axios'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import AppFixedHeader from './components/AppFixedHeader'
import ScrollSlider from './components/ScrollSlider'
import NewsSlider from './components/NewsSlider'
import NewsLetter from './components/NewsLetter'
import TestimonialSlider from './components/TestimonialSlider'
import HeroSlider from './components/HeroSlider'
import AppNavBar from './components/AppNavBar'
import Modal from './components/Modal'
import ModalTwo from './components/ModalTwo'
import Project from './components/Project'
import ContactForm from './components/ContactForm'
import QuoteForm from './components/QuoteForm'
import AuditForm from './components/AuditForm'
import CookieConsent from './components/CookieConsent'
Vue.component('CookieConsent', CookieConsent)
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('Modal', Modal)
Vue.component('ModalTwo', ModalTwo)
Vue.component('Project', Project)
Vue.component('ScrollSlider', ScrollSlider)
Vue.component('NewsLetter', NewsLetter)
Vue.component('NewsSlider', NewsSlider)
Vue.component('TestimonialSlider', TestimonialSlider)
Vue.component('HeroSlider', HeroSlider)
Vue.component('ContactForm', ContactForm)
Vue.component('AuditForm', AuditForm)
Vue.component('QuoteForm', QuoteForm)


// import {VueRecaptchaPlugin} from 'vue-recaptcha';
// Vue.use(VueRecaptchaPlugin, {
//   v3SiteKey: '6Lf9TeopAAAAAOFAsbM5e9naXZYCtLCw-L_gsPdv',
// });


const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.prototype.$axios = axios
Vue.config.productionTip = false


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})
$(document).ready(function(){
  if ($(".workcon")[0]){
    $('.controls button').click(function(){
      $('.controls .current').removeClass('current');
      $(this).addClass('current');
    });
    var loadMoreEl = document.querySelector('.load-more');
    var changeEl = document.querySelector('.data-selector');
    if(window.matchMedia("(max-width: 767px)").matches){
      // The viewport is less than 768 pixels wide
      var initShow = 4;
    } else{
      // The viewport is at least 768 pixels wide
      var initShow = 9;
    }
    var currentLimit = initShow;
    var incrementAmount = initShow;

    var mixer = mixitup('.containerss', {
      pagination: {
        limit: initShow,
        maintainActivePage: false,
      },
      controls: {
        live: true
      },
      callbacks: {
        onMixClick: changeCategoryClick,
        onMixEnd: handleMixEnd,
      }
    });
    function handleMixEnd(state) {
      if (state.activePagination.limit + 1 >= state.totalMatching) {
        loadMoreEl.disabled = true;
      } else if (loadMoreEl.disabled) {
        loadMoreEl.disabled = false;
      }
    }
    function handleLoadMoreClick() {
      currentLimit += incrementAmount;
      mixer.paginate({limit: currentLimit});
    }
    loadMoreEl.addEventListener('click', handleLoadMoreClick);
    function changeCategoryClick() {
      currentLimit = currentLimit - currentLimit + initShow;
      mixer.paginate({limit: initShow});
    }
    changeEl.addEventListener('click', changeCategoryClick);
    // end mixitup
  }
  $('.toggle-me').on('click', function() {
    if ($('.menu-item').hasClass('visible')) {
      $('.menu-item').removeClass('visible');
      $('.menu-item').removeClass('visibles');
      $('.comp').removeClass('visibles');
      $(".scrollers").delay(300).animate({ scrollTop: 0 }, "fast");
    }
    else
    {
      document.querySelectorAll("#menu-main-menu li").forEach((li, i) => {
        setTimeout(() => {
          li.classList.add('visible');
        }, 50 * i);
      });
      setTimeout(function(){
      $('.comp').addClass('visibles');
      },400);
      setTimeout(function(){
        document.querySelectorAll("#menu-main-menus li").forEach((li, i) => {
          setTimeout(() => {
            li.classList.add('visibles');
          }, 50 * i);
        });
      },400);

    }
  });
  $('#nav-icon4').click(function(){
    $(this).toggleClass('open');
  });
  $('.accordion-list .answer').hide();

  $('.accordion-list button').click(function() {
    let parent = $(this).parent();
    if ($(this).parent().hasClass("active")) {
      parent.removeClass('active');
      parent.find('.answer').slideUp();
      parent.find('button').attr('aria-expanded', 'false').attr('hidden', 'hidden');
    } else {
      let previousActive = $(".accordion-list .active");
      if (previousActive) {
        previousActive.find('.answer').slideUp();
        previousActive.find('button').attr('aria-expanded', 'false').attr('hidden', 'hidden');
        previousActive.removeClass('active');
      }

      parent.addClass("active");
      parent.find(".answer").slideDown();
      parent.find("button").attr('aria-expanded', 'true').removeAttr('hidden');
    }
    return false;
  });
  $(".toggler").click(function(){
    $(".tog-me").toggleClass("mt-1000px");
  });
  $(".togglercard").click(function(){
    $(this).toggleClass("moved-card");
  });

  if ($(".home")[0]){
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if (scroll >= 0.4 * $(window).height()) {
        $("#nav").addClass('fadeout');
        $(".sidebtn").removeClass('lg:-right-48');
        $(".sidebtn").addClass('lg:-right-16');
      } else {
        $("#nav").removeClass('fadeout');
        $(".sidebtn").addClass('lg:-right-48');
        $(".sidebtn").removeClass('lg:-right-16');
      }
    });
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= window.innerHeight) {
        $(".projecttors").addClass("bottom-0");
        $(".projecttors").addClass("flex");
        $(".projecttors").removeClass("-bottom-56");
      } else {
        $(".projecttors").removeClass("bottom-0");
        $(".projecttors").addClass("-bottom-56");
        $(".projecttors").addClass("flex");
      }
    });
  }else{
    $(".sidebtn").addClass("lg:-right-16").delay(5000).removeClass("lg:-right-48");
    setInterval(function(){
      $(".projecttors").addClass("bottom-0");
      $(".projecttors").addClass("flex");
      $(".projecttors").removeClass("-bottom-56");
    }, 2000);

  }
});
