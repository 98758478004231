<template>
  <div>
    <div class="">
        <button class="text-black absolute bottom-4 right-4 bg-primary  px-4 py-2 rounded-xl justify-center min-w-[180px] inline-flex items-center gap-x-4 " @click="selecttab(1);hidebod();height();">
          <slot name="button" />
        </button>
      <div v-if="selected===1" class="fixed opacity-100 z-50 w-screen h-screen flex items-center top-0 left-0 right-0 bottom-0">
        <div class="bg-black opacity-50 absolute z-50 w-screen h-screen" @click="selecttab(0);hidebod();height();"></div>
        <div class="mx-auto bg-white relative z-50 opacity-100 rounded-lg modal-l p-4 w-10/12 lg:max-w-3xl">
          <button type="button" name="button" class="text-black hover:text-white text-xl bg-primary hover:bg-black transition-all ease-in-out duration-300 p-1 z-10 rounded-full absolute -right-3 -top-3" @click="selecttab(0);hidebod();height();">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="relative lg:text-base text-sm">
            <slot />
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: 0,
    }
  },
  methods: {
  selecttab(tab){
    this.selected = tab
  },
  hidebod () {
    this.isOpen = true
    document.querySelector('body').classList.toggle('overflow-hidden')
  },
  height () {
    this.isOpen = true
    document.querySelector('body').classList.toggle('max-h-screen')
  },
},
}
</script>
