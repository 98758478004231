<template>
  <div style="padding:0 10px;" v-swiper:ReviewSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 lg:hidden project-pagination fibre-pagination swiper-pagination"></div>
      <div class="swiper-scrollbar hidden lg:block mt-20">
        <div class="line h-px" style="margin-bottom:-3.5px;background:#40454E;"></div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        autoHeight: true,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          dragSize: 100.
        },
        pagination: {
          clickable: true,
          el: '.project-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
          }
        }
      }
    }
  }
}
</script>
