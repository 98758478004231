<template>
  <div>
    <div id="nav" class="relative top-navigation transition-all ease-in-out duration-300 bg-transparent lg:bg-white z-50">
      <div class="w-screen relative bg-transparent lg:bg-white px-8">
        <div class="container py-4 lg:py-8 flex justify-between items-center ">
          <div class="relative z-50 bg-transparent lg:bg-white mb-0">
            <slot name="brand" />
          </div>
         <div class="flex gap-x-12 items-center">
           <div class="lg:block hidden">
             <div class="second-menu flex items-center mt-1 gap-x-8">
               <a href="/about" class="learn-more relative z-50 animate fade-bottom animate-active">
                 <span aria-hidden="true" class="circle"></span>
                 <span class="!font-normal relative items-center py-3 px-2 flex gap-x-4">
                      about
                 </span>
               </a>
               <div @click="submen()">
                 <button class="relative learn-more animate fade-bottom animate-active">
                   <span aria-hidden="true" class="circle"></span>
                   <span class="!font-normal relative items-center py-3 px-2 flex gap-x-2">
                      services
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 transition-all ease-in-out duration-300 service-arrow">
                     <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                   </svg>
                 </span>
                 </button>
                 <div class="absolute z-minus services-positioner hidden lg:block bg-white w-screen left-0 right-0 border-t border-b border-[#D9D9D9]">
                   <slot name="services" />
                 </div>
               </div>
               <a href="/portfolio" class="learn-more animate fade-bottom animate-active">
                 <span aria-hidden="true" class="circle"></span>
                 <span class="!font-normal relative items-center py-3 px-2 flex gap-x-4">
                      portfolio
                 </span>
               </a>
               <a href="/case-studies" class="learn-more animate fade-bottom animate-active">
                 <span aria-hidden="true" class="circle"></span>
                 <span class="!font-normal relative items-center py-3 px-2 flex gap-x-4">
                      case studies
                 </span>
               </a>
               <a href="/jobs" class="learn-more animate fade-bottom animate-active">
                 <span aria-hidden="true" class="circle"></span>
                 <span class="!font-normal relative items-center py-3 px-2 flex gap-x-4">
                      jobs
                 </span>
               </a>
               <a href="/blog" class="learn-more animate fade-bottom animate-active">
                 <span aria-hidden="true" class="circle"></span>
                 <span class="!font-normal relative items-center py-3 px-2 flex gap-x-4">
                      insights
                 </span>
               </a>
               <a href="/contact" class="learn-more animate fade-bottom animate-active">
                 <span aria-hidden="true" class="circle"></span>
                 <span class="!font-normal relative items-center py-3 px-2 flex gap-x-4">
                      contact
                 </span>
               </a>
             </div>
           </div>
           <div class="h-6 lg:hidden">
             <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;menuactive();" class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
               <div id="nav-icon4" :class="{ open: isActive }">
                 <span></span>
                 <span></span>
               </div>
             </button>
           </div>
         </div>
        </div>
      </div>
    </div>
      <div :class="{ desktopmenu: isActive }"  class="absolute transition-all ease-in-out duration-300 lg:hidden mobile-menu left-0 right-0 bottom-0 bg-black z-20 h-screen w-screen overflow-x-hidden overflow-y-scroll">
          <div class="pt-32 pb-48 relative px-8">
              <div class="container main-menu">
                <ul class="mb-4 sm:mb-8">
                  <li id="menu-item-18" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18 visible">
                    <a href="/about/">about</a>
                  </li>
                </ul>
                <div class="text-center">
                  <button @click="servmenu();" class="menu-item mb-4 -mr-8 inline-flex items-center gap-x-4 sm:mb-8 text-center menu-item-type-post_type menu-item-object-page menu-item-18 visible">
                      services
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="17.547" viewBox="0 0 21.414 17.547">
                      <g id="Group_7343" data-name="Group 7343" transform="translate(0 0.707)">
                        <path id="Path_122" data-name="Path 122" d="M-22775.713-11709.713l8.066,8.066-8.066,8.066" transform="translate(22787.646 11709.713)" fill="none" stroke="#fff" stroke-width="2"/>
                        <line id="Line_113" data-name="Line 113" x1="11.935" transform="translate(0 8.199)" fill="none" stroke="#fff" stroke-width="2"/>
                      </g>
                    </svg>
                  </button>
                </div>
                <slot name="mainmenu" />
              </div>
            <div class="container pt-32 pb-48 top-0 w-full h-full bottom-0 bg-black transition-all ease-in-out duration-500 absolute service-menu">
              <button @click="servmenu();" class="flex text-24 items-center font-semibold justify-center w-full -ml-7 text-primary gap-x-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="17.547" viewBox="0 0 21.414 17.547" class="rotate-180">
                  <g id="Group_7343" data-name="Group 7343" transform="translate(0 0.707)">
                    <path id="Path_122" data-name="Path 122" d="M-22775.713-11709.713l8.066,8.066-8.066,8.066" transform="translate(22787.646 11709.713)" fill="none" stroke="#fff" stroke-width="2"/>
                    <line id="Line_113" data-name="Line 113" x1="11.935" transform="translate(0 8.199)" fill="none" stroke="#fff" stroke-width="2"/>
                  </g>
                </svg>
                back
              </button>
              <ul class="text-center mt-10 space-y-4 sm:space-y-8">
                <li>
                  <a href="/services/brand/" class="text-white text-24 hover:text-primary transition-all ease-in-out duration-300">brand</a>
                </li>
                <li>
                  <a href="/services/web/" class="text-white text-24 hover:text-primary transition-all ease-in-out duration-300">web</a>
                </li>
                <li>
                  <a href="/services/3d-cgi/" class="text-white text-24 hover:text-primary transition-all ease-in-out duration-300">3d/cgi</a>
                </li>
                <li>
                  <a href="/services/social/" class="text-white text-24 hover:text-primary transition-all ease-in-out duration-300">socials</a>
                </li>
              </ul>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    submen(){
      document.querySelector('.services-positioner').classList.toggle('sub-active');
      document.querySelector('.service-arrow').classList.toggle('rotate-180');
    },
    servmenu(){
      document.querySelector('.service-menu').classList.toggle('mobile-services')
    },
    menuactive(){
      document.querySelector('.mobile-menu').classList.toggle('active-menu');
      document.querySelector('.second-menu').classList.toggle('hidden');
      document.querySelector('.brand').classList.toggle('whitel');
      document.querySelector('body').classList.toggle('overflow-hidden');
      document.querySelector('body').classList.toggle('max-h-screen');

    },
  }
}
</script>
<style lang="scss">
.moverin{
  right: -101vw;
}
.onscreen{
  right: 0!important;
}
.offscreen .mover{
  left: -101vw!important;
}
.invisi{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-left: -200vw;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-left: 0;
}
.slideit{
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-left: -105vw;
  }
  30% {
    margin-left: 0;
  }
  70%{
    margin-left: 0;
  }
  100% {
    margin-left: 105vw;
  }
}
</style>
