<template>
  <div style="overflow-x: clip;" v-swiper:HeroSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeroSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        loop: true,
        direction: 'vertical',
        spaceBetween: 30,
        autoHeight: true,
        noSwiping: false,
        speed: 1000,
        loopedSlides: 8,
        autoplay: {
          delay: 3500,
        },
      }
    }
  }
}
</script>
