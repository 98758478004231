<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8" role="alert">
      <div class="flex">
        <div>
          <div class="w-4 h-1 bg-black"></div>
          <p class="lg:text-45 text-30 lg:leading-45 font-semibold">thank you for your enquiry!</p>
          <p class="mt-6">A member of our team will be in touch with you as
            soon as possible.</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-black border-black bg-transparent p-4" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="h-6 w-6 mr-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div>
          <p class="font-semibold">Houston - we have a problem!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>

    <form name="contact-form"
          :class="{ hidden: success }"
          class="grid gap-x-6 gap-y-6 text-left md:grid-cols-4"
          @submit.stop
    >
      <validation-provider v-slot="{ errors }" rules="required" name="Firstname" class="md:col-span-4">
        <div>
          <input
              id="form-firstname"
              placeholder="Full Name"
              v-model="form.firstname"
              :class="{ error: errors[0] }"
              type="text"
              class="input"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email" rules="required|email" class="md:col-span-4">
        <div>
          <input
              id="form-email"
              placeholder="Email"
              v-model="form.email"
              :class="{ error: errors[0] }"
              type="text"
              class="input"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Message" class="md:col-span-4">
        <div>
          <textarea
              placeholder="Tell us how we can help…"
              id="form-message"
              v-model="form.message"
              :class="{ error: errors[0] }"
              class="text-area"
              rows="4"
          ></textarea>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>

      <div class="md:col-span-4">
        <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            sitekey="6Ld1Z-opAAAAAAPp8hpzX-wcbpu1UHa5tRAajVcF"
             :loadRecaptchaScript="true"
            @verify="SendMessage">

        </vue-recaptcha>
        <button type="button" :disabled="invalid" class="learn-more lg:hidden"
                @click="passes(SubmitForm);scrollToTop();">
          <span class="circle" aria-hidden="true"></span>
          <span class="button-text flex gap-x-4">send enquiry
            <img aria-hidden="true" width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg"
                 alt="arrow">
          </span>
        </button>
        <button type="button" :disabled="invalid" class="learn-more contact-counter hidden lg:inline-block"
                @click="passes(SubmitForm);scrollToTop();">
          <span class="circle" aria-hidden="true"></span>
          <span class="button-text flex gap-x-4">
            send enquiry
            <img aria-hidden="true" width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg"
                 alt="arrow">
          </span>
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {VueRecaptcha} from 'vue-recaptcha'
export default {
  name: 'ContactForm',
  components: {
    'vue-recaptcha': VueRecaptcha,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      form: {}
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    Reset() {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage() {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
          .then((response) => {
            this.form = {} // Clear input fields.
            this.$nextTick(() => this.$refs.form.reset())
            this.success = response.data.message
            this.error = false;

            const elementsToHide = document.querySelectorAll('.hide-on-contact-success');
            for(const element of elementsToHide) {
              element.remove();
            }
          })
          .catch((err) => {
            this.success = false
            this.error = err.response.data.message
            /*
            this.$notify({
              message: err.response.data.message
            })
            */
          })
          .then(() => {
            this.loading = false
          })
    },
    SubmitForm() {
      this.$refs.recaptcha.execute()
    }
  }
}
</script>
<style>
input:active {
  border: none !important;
}

input:focus-visible {
  outline-width: 0px;
}

textarea:focus-visible {
  outline-width: 0px;
}
</style>
