<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 text-white border-white bg-transparent" role="alert">
      <div class="flex">
        <div>
          <div class="w-4 h-1 bg-black"></div>
          <p class="text-black lg:text-45 text-30 lg:leading-45 font-semibold">thank you for your enquiry!</p>
          <p class="text-sm text-black mt-6 mb-48 lg:mb-72 lg:w-2/3">A member of our team will be in touch with you as soon as possible to discuss your project.</p>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white bg-transparent p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-semibold font-axia">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form  name="contact-form" :class="{ hidden: success }" class="grid gap-x-6 formsy gap-y-6 text-left md:grid-cols-6" @submit.stop>
      <div class="md:col-span-4 md:grid gap-x-6 text-left">
        <div class="col-span-4">
          <div :class="{ hidden: success }" class="w-4 mb-2 h-1 bg-black"></div>
          <p :class="{ hidden: success }" class="text-black lg:text-45 text-30 lg:leading-45 font-semibold">i’ve a project in mind</p>
          <p :class="{ hidden: success }" class="body-text mb-12">Share your details and an outline of your objectives to kick off the process.</p>
        </div>
        <validation-provider v-slot="{ errors }" name="Firstname" rules="required" class="md:col-span-2 mb-4 block sm:mb-0">
          <div>

            <input
                id="form-firstname"
                placeholder="Full Name"
                v-model="form.firstname"
                :class="{ error: errors[0] }"
                type="text"
                class="input"
            />
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="Company" class="md:col-span-2 mb-4 block sm:mb-0">
          <div>

            <input
                id="form-company"
                placeholder="Company"
                v-model="form.company"
                :class="{ error: errors[0] }"
                type="text"
                class="input"
            />
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="Email" rules="required|email" class="md:col-span-2 mb-4 block sm:mb-0">
          <div>

            <input
                id="email"
                placeholder="Email"
                v-model="form.email"
                :class="{ error: errors[0] }"
                type="text"
                class="input"

            />
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="budget" class="md:col-span-2 mb-4 block sm:mb-0">
          <div>

            <input type="hidden" v-model="form.budget" name="budget" />
            <select
                class="form-input rounded-none text-gray-400 input focus:border-grey-500 focus-visible:border-grey-500"
                name="budget"
                aria-label="budget"
                @change="onChange($event)"
                v-model="selectvalue"
                id="form-subject"
                :class="{ error: errors[0] }"
            >
              <option selected value="" disabled>Budget Estimate</option>
              <option value="20k+">£20,000 +</option>
              <option value="15-20k">£15,000 - £20,000</option>
              <option value="10-15k">£10,000 - £15,000</option>
              <option value="5-10k">£5,000 - £10,000</option>
              <option value="1.5-5k">£1,500 - £5,000</option>
            </select>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
          <validation-provider v-slot="{ errors }" name="interested" class="lg:col-span-2 mb-4 block md:hidden">
            <div>
              <input type="hidden" v-model="form.interested" name="interested" />
              <select
                  class="form-input rounded-none text-gray-400 input focus:border-grey-500 focus-visible:border-grey-500"
                  name="interested"
                  aria-label="interested"
                  @change="onChange($event)"
                  v-model="selectvalues"
                  id="form-interested"
                  :class="{ error: errors[0] }"
              >
                <option selected value="" disabled>I am interested in</option>
                <option value="Branding">Branding</option>
                <option value="Website Design">Website Design</option>
                <option value="Bespoke Software">Bespoke Software</option>
                <option value="3D / CGI">3D / CGI</option>
                <option value="Video & Animation">Video & Animation</option>
                <option value="Social Media Graphics">Social Media Graphics</option>
                <option value="Advertising">Advertising</option>
                <option value="Graphic Design">Graphic Design</option>
              </select>
              <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        <validation-provider v-slot="{ errors }" name="Message" class="md:col-span-4 mb-4 block sm:mb-0">
          <div>
          <textarea
              placeholder="Tell us a bit about your project"
              id="form-message"
              v-model="form.message"
              :class="{ error: errors[0] }"
              class="text-area"
              rows="4"
          ></textarea>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="md:col-span-4">
          <button  type="button" :disabled="invalid" class="learn-more lg:hidden" @click="passes(SendMessage);reposition();formh();">
                  <span class="circle" aria-hidden="true">
                  </span>
            <span class="button-text flex gap-x-4">send enquiry <img width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg" alt="arrow"></span>
          </button>
          <button  type="button" :disabled="invalid" class="learn-more hidden lg:inline-block" @click="passes(SendMessage);">
                  <span class="circle" aria-hidden="true">
                  </span>
            <span class="button-text flex gap-x-4">send enquiry <img width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg" alt="arrow"></span>
          </button>
        </div>
      </div>
      <div class="md:col-span-2 md:block pt-3 hidden">
        <p :class="{ hidden: success }" class="body-text mt-12 mb-12"><b>I'm am interested in</b> <br><span class="text-sm sm:text-base">(please tick):</span></p>

        <validation-provider v-slot="{ errors }" name="branding" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-branding"
                    placeholder="branding"
                    v-model="form.branding"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"
                >
                <span class="checkmark"></span>
              </label>
              Branding
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="webdesign" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-webdesign"
                    placeholder="webdesign"
                    v-model="form.webdesign"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"
                >
                <span class="checkmark"></span>
              </label>
              Website Design
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="software" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-software"
                    placeholder="software"
                    v-model="form.software"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"
                >
                <span class="checkmark"></span>
              </label>
              Bespoke Software
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="cgi" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-cgi"
                    placeholder="cgi"
                    v-model="form.cgi"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox">
                <span class="checkmark"></span>

              </label>
              3D / CGI
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="video" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-video"
                    placeholder="video"
                    v-model="form.video"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"

                >
                <span class="checkmark"></span>
              </label>
              Video & Animation
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="smm" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-smm"
                    placeholder="smm"
                    v-model="form.smm"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"
                >
                <span class="checkmark"></span>
              </label>
              Social Media Graphics
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="advertising" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-advertising"
                    placeholder="advertising"
                    v-model="form.advertising"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"
                >
                <span class="checkmark"></span>
              </label>
              Advertising
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="gd" class="md:col-span-4">
          <div>
            <p class="flex gap-x-2 text-black font-semibold">
              <label class="check-box">
                <input
                    id="form-gd"
                    placeholder="gd"
                    v-model="form.gd"
                    :class="{ error: errors[0] }"
                    type="checkbox"
                    class="checkbox"
                >
                <span class="checkmark"></span>
              </label>
              Graphic Design
            </p>
            <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
          </div>
        </validation-provider>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      selectvalue: '',
      selectvalues: '',
      success: false,
      error: false,
      form: {
        budget: this.selectvalue,
        interested: this.selectvalues,
      }
    }
  },
  methods: {

    reposition () {
      this.isOpen = true
      document.querySelector('.repos').classList.toggle('h-small')
    },
    formh () {
      this.isOpen = true
      document.querySelector('.formsy').classList.toggle('hidden')
    },
    onChange(event) {
      this.form.budget = this.selectvalue,
      this.form.interested = this.selectvalues
    },
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
          .then((response) => {
            this.form = {} // Clear input fields.
            this.$nextTick(() => this.$refs.form.reset())
            this.success = response.data.message
            this.error = false
            /*
            this.$notify({
              type: 'primary',
              showMethod: 'fade in right',
              message: response.data.message
            })*/
          })
          .catch((err) => {
            this.success = false
            this.error = err.response.data.message
            /*
            this.$notify({
              message: err.response.data.message
            })
            */
          })
          .then(() => {
            this.loading = false
          })
    }
  }
}
</script>
<style>
input:active{
  border:none!important;
}
input:focus-visible{
  outline-width: 0px;
}
textarea:focus-visible{
  outline-width: 0px;
}
</style>
