<template>
  <div>
    <div class="">
      <button
          @click="selecttab(1);hidebod();height();onscreen();branding();"
          class="learn-more">
            <span class="circle" aria-hidden="true">
                  </span>
        <span class="button-text flex gap-x-4">Let's Go
          <img width="16" height="22" src="/app/themes/wordpress/assets/images/arrow.svg" alt="arrow">
        </span>
      </button>
      <div
          class="fixed lg:top-0 z-50 bg-white pt-32 sm:pt-16 off-screen transition-all overflow-y-scroll ease-in-out duration-500 w-screen h-screen lg:flex lg:items-center">
        <div class="lg:absolute top-20 lg:top-8 px-4 lg:px-8 left-0 right-0">
          <div class="flex justify-between container">
            <a href="/" aria-label="Site Logo" class="linker block brand" rel="home">
              <svg fill="#021b33" xmlns="http://www.w3.org/2000/svg" width="79.744" height="79.744"
                   viewBox="0 0 79.744 79.744">
                <path d="M4.228,4.227h71.29V75.516H4.228ZM0,79.744H79.744V0H0Z"/>
                <rect width="4.058" height="18.067" transform="translate(22.213 14.649)"/>
                <path
                    d="M133.811,50.458a9.187,9.187,0,0,1,9.339-9.412,9.6,9.6,0,0,1,7.75,3.667l-2.64,2.69a6.365,6.365,0,0,0-4.89-2.47,5.346,5.346,0,0,0-5.378,5.5,5.258,5.258,0,0,0,5.353,5.4,6.559,6.559,0,0,0,5.013-2.494l2.713,2.543A9.928,9.928,0,0,1,143.1,59.7a8.978,8.978,0,0,1-9.289-9.242"
                    transform="translate(-87.01 -26.69)"/>
                <path d="M151.733,134.237V152.3h-2.909L140,141.841V152.3h-3.985V134.237h2.739l9.021,10.561V134.237Z"
                      transform="translate(-88.442 -87.287)"/>
                <path
                    d="M51.937,133.343a9.347,9.347,0,1,0,9.346,9.346,9.346,9.346,0,0,0-9.346-9.346m0,14.618a5.272,5.272,0,1,1,5.272-5.273,5.278,5.278,0,0,1-5.272,5.273"
                    transform="translate(-27.695 -86.706)"/>
              </svg>
            </a>
            <button @click="selecttab(1);hidebod();height();onscreen();branding();vis();" type="button"
                    name="back-to-page" aria-label="back-to-page"
                    class="text-black text-xl transition-all ease-in-out duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-12 h-12">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="px-4 lg:px-8 h-full repos lg:flex lg:items-center lg:mt-48 w-full">
          <div class="container bg-white mt-12 sm:mt-8 md:mt-0 relative sm:pb-0 ">
            <div class="lg:pt-0 pb-32">
              <quote-form></quote-form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: 0,
    }
  },
  methods: {
    selecttab(tab) {
      this.selected = tab
    },
    hidebod() {
      this.isOpen = true
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    onscreen() {
      this.isOpen = true
      document.querySelector('.off-screen').classList.toggle('on-screen')
    },
    branding() {
      this.isOpen = true
      document.querySelector('#nav').classList.toggle('black-branding')
    },
    height() {
      this.isOpen = true
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  },
}
</script>
